import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":_vm.mapDialogSize == 'ls' ? '100%' : '1000'},model:{value:(_vm.dialogs.detail),callback:function ($$v) {_vm.$set(_vm.dialogs, "detail", $$v)},expression:"dialogs.detail"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,{staticClass:"map-card--title"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',[_vm._v("Parcel ID: ")]):_vm._e(),_c('span',{staticClass:"ml-1 map-parcel--text"},[_vm._v(" "+_vm._s(_vm.removePadding(_vm.parcel.number))+" ")]),(_vm.getStreetAddress(_vm.parcel))?_c(VSpacer):_vm._e(),(_vm.getStreetAddress(_vm.parcel))?_c('span',[_vm._v(" "+_vm._s(_vm.getStreetAddress(_vm.parcel))+" ")]):_vm._e(),(_vm.parcel.acres)?_c(VSpacer,{staticClass:"d-none d-sm-flex"}):_vm._e(),(_vm.parcel.acres)?_c('span',{staticClass:"d-none d-sm-flex"},[_vm._v(" "+_vm._s(_vm.parcel.acres)+" Acres ")]):_vm._e(),_c(VSpacer),_c(VMenu,{attrs:{"close-on-content-click":false,"left":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.isLandOnlyParcel(_vm.parcel.parcel_type))?_c(VBtn,_vm._g({staticStyle:{"margin-left":"30px"},attrs:{"color":"info","icon":""}},on),[_c(VIcon,[_vm._v("mdi-information")])],1):_vm._e()]}}]),model:{value:(_vm.dialogs.stat),callback:function ($$v) {_vm.$set(_vm.dialogs, "stat", $$v)},expression:"dialogs.stat"}},[_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"400"}},[_c(VCardTitle,[_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialogs.stat = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VContainer,[_c(VSimpleTable,{attrs:{"fixed-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',{staticClass:"d-sm-none"},[_c('th',[_vm._v("Acres")]),_c('td',[_vm._v(" "+_vm._s(_vm.parcel.acres)+" ")])]),_vm._l((_vm.parcelStatsComputed),function(data,index){return _c('tr',{key:index},[_c('th',[_vm._v(_vm._s(data.name))]),_c('td',[_vm._v(_vm._s(data.description))])])})],2)]},proxy:true}])})],1)],1)],1),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"margin-top":"-7px !important"}},on),[_c('property-card-key',{attrs:{"data":_vm.parcel,"textBtn":"","witdh":"auto","autoWidth":""},scopedSlots:_vm._u([{key:"cardKey",fn:function(ref){
var cardKey = ref.cardKey;
return [_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(" "+_vm._s(cardKey)+" ")])]}}],null,true)})],1)]}}])},[_c('span',[_vm._v("Prop Card "+_vm._s(_vm.parcel.key))])]),_c(VIcon,{on:{"click":function($event){return _vm.showMapView(_vm.parcel)}}},[_vm._v("mdi-map")]),_c(VSpacer),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.$vuetify.breakpoint.lgAndUp)?_c(VBtn,_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.changeMapDialogSize()}}},on),[_c(VIcon,[_vm._v(_vm._s(_vm.mapDialogSize == 'ls' ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'))])],1):_vm._e()]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.mapDialogSize == 'ls' ? 'Reduce Screen' : 'Expand Screen')+" ")])]),_c(VMenu,{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({},on),[_vm._v("mdi-cog")])]}}]),model:{value:(_vm.dialogs.mapSetting),callback:function ($$v) {_vm.$set(_vm.dialogs, "mapSetting", $$v)},expression:"dialogs.mapSetting"}},[_c('map-settings',{attrs:{"show":['style', 'scale']},on:{"close":function($event){_vm.dialogs.mapSetting = false}}})],1),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialogs.detail = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c('mgl-map',{ref:"map",staticStyle:{"width":"100%","height":"85vh","position":"relative"},attrs:{"center":_vm.parcel.center,"mapStyle":_vm.mapStyle,"zoom":15},on:{"load":_vm.onDetailMapLoad}},[_c('MglGeolocateControl',{attrs:{"position":"top-right"}}),_c('mgl-marker',{attrs:{"coordinates":_vm.parcel.center}}),(_vm.scaleFilter)?_c('mgl-scale-control',{attrs:{"position":"bottom-right"}}):_vm._e(),_c('map-coordinates')],1)],1)],1),(_vm.parcel.center)?_c(VSheet,{staticClass:"pa-2 mt-3 map-container",on:{"click":function($event){$event.preventDefault();return _vm.showMapDialog($event)}}},[_c('div',{ref:"thumbnailContainer",staticClass:"thumbnail-container"},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',[(hover || _vm.mapRendered || _vm.hideThumbnailImage)?_c('div',[_c('mgl-map',{key:_vm.mapKey,ref:"mapThumbnail",staticClass:"map-clickable",staticStyle:{"height":"200px","width":"100%"},attrs:{"center":_vm.parcel.center,"mapStyle":_vm.mapStyle,"zoom":15},on:{"load":_vm.onMapLoad}},[_c('mgl-marker',{attrs:{"coordinates":_vm.parcel.center}})],1)],1):_c('div',[(_vm.parcelMapSrc(_vm.parcel))?_c(VImg,{staticStyle:{"height":"200px","width":"100"},attrs:{"src":_vm.parcelMapSrc(_vm.parcel),"alt":""}}):_vm._e()],1)])]}}],null,false,4180959360)})],1),_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"map-hint"},[_vm._v("Click to see larger map")])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }