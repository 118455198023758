<template>
  <div>
    <v-dialog v-model="dialogs.detail" :max-width="mapDialogSize == 'ls' ? '100%' : '1000'">
      <v-card class="mx-auto">
        <v-card-title class="map-card--title">
          <span v-if="$vuetify.breakpoint.mdAndUp">Parcel ID: </span>
          <span class="ml-1 map-parcel--text">
            {{ removePadding(parcel.number) }}
          </span>
          <v-spacer v-if="getStreetAddress(parcel)"></v-spacer>
          <span v-if="getStreetAddress(parcel)">
            {{ getStreetAddress(parcel) }}
          </span>
          <v-spacer v-if="parcel.acres" class="d-none d-sm-flex"></v-spacer>
          <span class="d-none d-sm-flex" v-if="parcel.acres"> {{ parcel.acres }} Acres </span>
          <v-spacer></v-spacer>
          <v-menu
            :close-on-content-click="false"
            left
            transition="slide-y-transition"
            v-model="dialogs.stat"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                style="margin-left: 30px"
                v-on="on"
                color="info"
                icon
                v-if="!isLandOnlyParcel(parcel.parcel_type)"
              >
                <v-icon>mdi-information</v-icon>
              </v-btn>
            </template>
            <v-card max-width="400" class="mx-auto">
              <v-card-title>
                <v-spacer></v-spacer>
                <v-btn @click="dialogs.stat = false" icon><v-icon>mdi-close</v-icon></v-btn>
              </v-card-title>
              <v-container>
                <v-simple-table fixed-header>
                  <template v-slot:default>
                    <tbody>
                      <tr class="d-sm-none">
                        <th>Acres</th>
                        <td>
                          {{ parcel.acres }}
                        </td>
                      </tr>
                      <tr v-for="(data, index) in parcelStatsComputed" :key="index">
                        <th>{{ data.name }}</th>
                        <td>{{ data.description }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-container>
            </v-card>
          </v-menu>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on" style="margin-top: -7px !important">
                <property-card-key :data="parcel" textBtn witdh="auto" autoWidth>
                  <template v-slot:cardKey="{ cardKey }">
                    <span class="d-none d-sm-inline">
                      {{ cardKey }}
                    </span>
                  </template>
                </property-card-key>
              </span>
            </template>
            <span>Prop Card {{ parcel.key }}</span>
          </v-tooltip>

          <v-icon @click="showMapView(parcel)">mdi-map</v-icon>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                @click="changeMapDialogSize()"
                v-if="$vuetify.breakpoint.lgAndUp"
              >
                <v-icon>{{
                  mapDialogSize == 'ls' ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'
                }}</v-icon>
              </v-btn>
            </template>
            <span>
              {{ mapDialogSize == 'ls' ? 'Reduce Screen' : 'Expand Screen' }}
            </span>
          </v-tooltip>

          <v-menu :close-on-content-click="false" v-model="dialogs.mapSetting">
            <template #activator="{ on }">
              <v-icon v-on="on">mdi-cog</v-icon>
            </template>
            <map-settings :show="['style', 'scale']" @close="dialogs.mapSetting = false">
            </map-settings>
          </v-menu>
          <v-btn @click="dialogs.detail = false" icon><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <mgl-map
          ref="map"
          @load="onDetailMapLoad"
          :center="parcel.center"
          :mapStyle="mapStyle"
          :zoom="15"
          style="width: 100%; height: 85vh; position: relative"
        >
          <MglGeolocateControl position="top-right" />
          <mgl-marker :coordinates="parcel.center"></mgl-marker>
          <mgl-scale-control v-if="scaleFilter" position="bottom-right"></mgl-scale-control>
          <map-coordinates />
        </mgl-map>
      </v-card>
    </v-dialog>

    <v-sheet class="pa-2 mt-3 map-container" v-if="parcel.center" @click.prevent="showMapDialog">
      <div class="thumbnail-container" ref="thumbnailContainer">
        <v-hover v-slot="{ hover }">
          <div>
            <div v-if="hover || mapRendered || hideThumbnailImage">
              <mgl-map
                :key="mapKey"
                ref="mapThumbnail"
                class="map-clickable"
                @load="onMapLoad"
                style="height: 200px; width: 100%"
                :center="parcel.center"
                :mapStyle="mapStyle"
                :zoom="15"
              >
                <mgl-marker :coordinates="parcel.center"></mgl-marker>
              </mgl-map>
            </div>
            <div v-else>
              <v-img
                v-if="parcelMapSrc(parcel)"
                :src="parcelMapSrc(parcel)"
                alt=""
                style="height: 200px; width: 100"
              />
            </div>
          </div>
        </v-hover>
      </div>
      <div class="text-center">
        <span class="map-hint">Click to see larger map</span>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import config from '../../config';
import { MglMap, MglMarker, MglScaleControl, MglGeolocateControl } from 'vue-mapbox';
import {
  parcelGeoJSON,
  addParcelLayer,
  removeLayer,
  removeSource,
  parseGeoJSON
} from '../../utils/map';
import { removePadding, getStreetAddress, isLandOnlyParcel } from '../../utils/parcel';
import PhotoStation from '../../services/photoStation';
import _ from 'lodash';
import bbox from '@turf/bbox';
import Map from '../../services/map';
import { geostylePhotoStation } from '../../utils/geostyles';
import mapboxgl from 'mapbox-gl';
import center from '@turf/center';
import distance from '@turf/distance';
export default {
  props: {
    hideThumbnailImage: {
      type: Boolean,
      default: false
    },
    noMapRedirect: {
      type: Boolean,
      default: false
    },
    parcel: {
      type: Object,
      required: true
    },
    images: {
      type: Array,
      default: () => {
        return [];
      }
    }
    // showPhotoStation: {
    //   type: Boolean,
    //   default: false
    // }
  },
  watch: {
    // showPhotoStation() {
    //   this.renderPhotoStation();
    // },
    // images() {
    //   this.renderPhotoStation();
    // },
    // circleRadius() {
    //   this.renderPhotoStation();
    // },
    // lineLength() {
    //   this.renderPhotoStation();
    // }
  },
  components: {
    MglMap,
    MglMarker,
    MglGeolocateControl,
    PropertyCardKey: () => import('./PropertyCardKey.vue'),
    MapSettings: () => import('./MapSettings.vue'),
    MglScaleControl,
    MapCoordinates: () => import('./MapInfo.vue')
  },
  data() {
    return {
      dialogs: {
        stat: false,
        mapSetting: false,
        detail: false
      },
      mapRendered: false,
      mapDialogSize: 'ms',
      zoom: {
        thumbnail: 15,
        detail: 15
      },
      // photoStation: new PhotoStation(),
      circleRadius: 20,
      lineLength: 40
    };
  },
  computed: {
    scaleFilter() {
      return this.$store.state.parcel.mapConfig.scale;
    },
    parcelStatsComputed() {
      let res = [];

      //simple nitrogen load
      let snlData = {
        name: 'Simple Nitrogen Load',
        description: ``
      };

      let snl = this.calculateSnl(this.parcel);
      if (snl === null) {
        snlData.description = `Acres and Bedrooms must be specified first.`;
      } else {
        snlData.description = `${snl}`;
      }
      res.push(snlData);

      //building covereage
      if (this.isNumber(this.parcel.acres) && this.isNumber(this.parcel.house_area)) {
        let buildingCoverage =
          (100 * Number(this.parcel.house_area)) / (44000 * Number(this.parcel.acres));
        buildingCoverage = buildingCoverage.toFixed(1);
        res.push({
          name: 'Building Coverage',
          description: `${buildingCoverage} %`,
          buildingCoverage
        });
      }
      return res;
    },
    mapKey() {
      return `${this.$store.state.parcel.mapConfig.style}`;
    },
    mapStyle: {
      get() {
        return this.$store.getters.mapStyle;
      },
      set(style) {
        this.$store.commit('setMapStyle', style);
      }
    }
  },
  methods: {
    isLandOnlyParcel,
    showMapView(parcel) {
      this.$store.commit('setParcelDetail', { key: 'map', value: false });
      this.$store.commit('setParcelDetail', { key: 'dialog', value: false });
      if (this.noMapRedirect) {
        this.dialogs.detail = false;
        this.$emit('close');
      } else {
        const url = `/parcels/map?center=${this.getParcelCenter(parcel)}`;
        this.$router.push(url);
      }
    },
    getParcelCenter(parcel) {
      let center = parcel.center || [];
      if (!center.length) {
        try {
          const gj = JSON.parse(parcel.geojson);
          center = gj.geometry.coordinates[0][0];
        } catch {}
      }
      return center;
    },
    nearby(nearby) {
      let res = [];
      let gj = this.parcelPolygonGeojson(this.data);
      gj.properties.description = '';
      res.push(gj);
      if (nearby) {
        if (this.tctFilter) {
          this.tctParcels.map(e => {
            res.push(this.parcelPolygonGeojson(e));
          });
        }
        if (this.totFilter) {
          this.totParcels.map(e => {
            res.push(this.parcelPolygonGeojson(e));
          });
        }
      }
      return res;
    },

    calculateSnl({ bedroom, acres, parcel_type }) {
      if (!bedroom || !acres) {
        return `Acres and Bedrooms must be specified first.`;
      }
      if (isNaN(bedroom) || isNaN(acres)) {
        return '';
      }
      let snl = Number(bedroom) / Number(acres);
      if (parcel_type == 'resPartTime') {
        snl = snl * 0.5;
      }
      return `${snl.toFixed(1)} bpa`;
    },
    removePadding,
    getStreetAddress,
    resizeMap(map) {
      map.resize();
    },
    changeMapDialogSize() {
      if (this.mapDialogSize == 'ms') {
        this.mapDialogSize = 'ls';
      } else {
        this.mapDialogSize = 'ms';
      }
      setTimeout(() => {
        this.resizeMap(this.$refs.map.map);
      }, 500);
    },

    showMapDialog() {
      this.dialogs.detail = true;
      // this.dialog = true;
    },

    onMapLoad(e) {
      this.mapRendered = true;
      const map = e.map;

      this.renderDetailMap({ map });
      // this.renderPhotoStation();
      map.on('style.load', () => {
        this.renderDetailMap({ map });
        // this.renderPhotoStation();
      });
    },

    enableHover(map, source) {
      const id = `${source}-fill`;
      let hoverId = null;
      map.on('mousemove', id, e => {
        map.getCanvas().style.cursor = 'pointer';
        if (e.features.length > 0) {
          if (hoverId !== null) {
            map.setFeatureState(
              {
                source: source,
                id: hoverId
              },
              {
                hover: false
              }
            );
          }
          hoverId = e.features[0].id;
          map.setFeatureState(
            {
              source: source,
              id: hoverId
            },
            {
              hover: true
            }
          );
        }
      });

      map.on('mouseleave', id, () => {
        map.getCanvas().style.cursor = '';
        if (hoverId !== null) {
          map.setFeatureState(
            {
              source: source,
              id: hoverId
            },
            {
              hover: false
            }
          );
        }
        hoverId = null;
      });

      if (this.hideThumbnailImage) {
        setTimeout(() => {
          const gps = this.$store.state.gps;
          if (!gps || !gps?.lng) return;
          const coordinates =
            this.parcel.center || center(parseGeoJSON(this.parcel.geojson)).geometry.coordinates;
          const dst = distance(coordinates, [gps.lng, gps.lat], { units: 'yards' });
          if (dst > 100) return;
          const control = new mapboxgl.GeolocateControl({
            positionOptions: {
              enableHighAccuracy: true
            },
            trackUserLocation: true,
            showUserHeading: true,
            showAccuracyCircle: false
          });

          map.addControl(control);
          setTimeout(() => {
            control.trigger();
          }, 1000);
        }, 500);
      }
    },

    onDetailMapLoad(e) {
      const map = e.map;

      this.renderDetailMap({ map, hover: true, ref: true });
      // this.renderPhotoStation();
      map.on('style.load', () => {
        this.renderDetailMap({ map, hover: true, ref: true });
        // this.renderPhotoStation();
      });
    },

    renderPhotoStation() {
      // if (this.showPhotoStation) {
      //   this.mapRendered = true;
      //   // this.$refs.thumbnailContainer.scrollIntoView({ top: 300, behavior: 'smooth' });
      // }
      // const mapThumbnail = this.$refs.mapThumbnail;
      // const mapDetail = this.$refs.map;
      // const maps = [mapThumbnail?.map, mapDetail?.map];
      // const photoStation = new PhotoStation();
      // photoStation.setCircleRadius(this.circleRadius);
      // photoStation.setLineLength(this.lineLength);
      // const map = new Map();
      // photoStation.setImages(this.images);
      // const geojson = Map.applyGeostyle(
      //   photoStation.photoStationGeoJSON(this.parcel?.geojson),
      //   geostylePhotoStation
      // );
      // const sourceId = 'photo-station';
      // const layers = map.mapLayers(sourceId);
      // maps.forEach(m => {
      //   if (!m) return;
      //   layers.forEach(layer => {
      //     if (m.getLayer(layer.id)) m.removeLayer(layer.id);
      //   });
      //   if (m.getSource(sourceId)) m.removeSource(sourceId);
      //   if (this.showPhotoStation) {
      //     m.addSource(sourceId, { type: 'geojson', data: geojson });
      //     layers.forEach(layer => {
      //       m.addLayer(layer);
      //     });
      //     this.enableHover(m, sourceId);
      //     m.on('click', `${sourceId}-fill`, e => {
      //       this.$emit('showGallery', e.features[0].properties.id);
      //     });
      //   }
      // });
    },

    renderDetailMap({ map, hover, ref } = {}) {
      removeSource(map, 'parcel');
      removeLayer(map, 'parcel-fill');
      removeLayer(map, 'parcel-outline');

      const { style } = this.$store.state.parcel.mapConfig;
      const data = parcelGeoJSON({
        parcel: this.parcel,
        referenceFilter: ref,
        mapStyle: style
      });

      const source = 'parcel';

      map.addSource(source, { type: 'geojson', data });
      addParcelLayer({ map, source });

      const bb = this.parcel.bbox || bbox(data);
      map.fitBounds(bb, {
        padding: 20
      });

      if (hover) {
        this.addParcelHoverEffect(map, 'parcel-fill', source);
      }
    },

    parcelMapSrc(data) {
      let { center, geojson } = data;

      if (geojson) {
        geojson = JSON.parse(geojson).geometry;
      } else {
        return '';
      }

      geojson = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              ...geojson
            }
          }
          // {
          //   type: 'Feature',
          //   properties: {},
          //   geometry: { type: 'Point', coordinates: [...center] }
          // }
        ]
      };

      // const style = this.mapStyle;
      // const regex = /mapbox:\/\/styles\/mapbox\/(.*)/;
      // const mapStyle = regex.exec(style)[1] || 'streets-v11';
      // const ms = String(this.$store.state.parcel?.mapConfig?.style || '').split('styles')[1];
      // const mapStyle = ms || '/mapbox/streets-v11';

      const res = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/geojson(${JSON.stringify(
        geojson
      )})/${center.join(',')},16/500x500?access_token=${config.mapboxToken}`;

      return res;
    }
  }
};
</script>

<style lang="sass" scoped>
@import "../../design/_colors.scss"

.map-container
  .map-hint
    color: $color-light-black

  .map-clickable
    cursor: pointer

.map-card--title
  // font-size: 16px !important
  height: 50px
  margin-top: -13px

@media (max-width:600px)
  .map-card--title
    font-size: 14px !important

.mapboxgl-popup--opacity-60
  opacity: 0.6
</style>
